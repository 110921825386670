/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.28.1
 * source: user.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export class LogoutRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): LogoutRequest {
        const message = new LogoutRequest({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LogoutRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LogoutRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): LogoutRequest {
        return LogoutRequest.deserialize(bytes);
    }
}
export class LogoutResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): LogoutResponse {
        const message = new LogoutResponse({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): LogoutResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new LogoutResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): LogoutResponse {
        return LogoutResponse.deserialize(bytes);
    }
}
export abstract class UnimplementedUserServiceService {
    static definition = {
        logout: {
            path: "/org.callistovault.core.UserService/logout",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: LogoutRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => LogoutRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: LogoutResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => LogoutResponse.deserialize(new Uint8Array(bytes))
        }
    };
    [method: string]: grpc_1.UntypedHandleCall;
    abstract logout(call: grpc_1.ServerUnaryCall<LogoutRequest, LogoutResponse>, callback: grpc_1.sendUnaryData<LogoutResponse>): void;
}
export class UserServiceClient {
    private _address: string;
    private _client: grpc_web_1.GrpcWebClientBase;
    constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
        if (!options)
            options = {};
        options.format = options.format || "text";
        this._address = address;
        this._client = new grpc_web_1.GrpcWebClientBase(options);
    }
    private static logout = new grpc_web_1.MethodDescriptor<LogoutRequest, LogoutResponse>("/org.callistovault.core.UserService/logout", grpc_web_1.MethodType.UNARY, LogoutRequest, LogoutResponse, (message: LogoutRequest) => message.serialize(), LogoutResponse.deserialize);
    logout(message: LogoutRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<LogoutRequest, LogoutResponse>(this._address + "/org.callistovault.core.UserService/logout", message, metadata || {}, UserServiceClient.logout);
    }
}
